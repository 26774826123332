import React from "react"
import { useContext } from "react"
import Wrapper from "../shared/wrapper"
import ZiptiContainer from "../shared/zipti_container"
import ZiptiButton from "../shared/zipti_button"
import ZiptiText from "../shared/zipti_text"
import { ApiContext, UserContext } from "../user_wrapper"
import { navigate } from "@reach/router"
import { useEffect } from "react"
import { useState } from "react"

const Admin: React.FC = props => {
  const apiClient = useContext(ApiContext)
  const [isAdmin, setIsAdmin] = useState<Boolean | undefined>(undefined)
  const user = useContext(UserContext)
  const handleButtonCreate = async () => {
    const res = await apiClient.createExpertInvite()
    navigator.clipboard.writeText(
      `zipti.com/expert_invite/${res.data.confirmation_code}`
    )
    console.log(res)
  }

  useEffect(() => {
    // TODO: Revisit this admin status checker, there's an issue with what I think is the async functionality...
    // setIsAdmin(user.data?.account?.is_admin)
    // console.log("isAdmin",isAdmin)
    // if (!isAdmin) navigate("/home")
  }, [])

  return (
    <Wrapper>
      <ZiptiContainer h="12rem">
        <ZiptiText mt="2rem">Expert Invite</ZiptiText>
        <ZiptiButton mt="2rem" onClick={() => handleButtonCreate()}>
          Copy Invitation
        </ZiptiButton>
      </ZiptiContainer>
    </Wrapper>
  )
}

export default Admin
