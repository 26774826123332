import { Container } from "@chakra-ui/layout"
import React from "react"

export interface ZiptiContainerProps {
  h?: string
  pt?: string
  mt?: string
  paddingInlineStart?: string
  paddingInlineEnd?: string
  position?: string
  children: React.ReactNode
}

const ZiptiContainer: React.FC<ZiptiContainerProps> = props => (
  <Container
    mt={{ base: "2rem", md: "4rem" }}
    centerContent={true}
    bg="white"
    w={{ base: "20rem", md: "32rem" }}
    h={{ base: "4rem", md: "16rem" }}
    borderWidth="3px"
    borderRadius="lg"
    {...props}
  >
    {props.children}
  </Container>
)

export default ZiptiContainer
